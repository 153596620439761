import { useUserStore } from '@voix/store/userStore'
import { $voixNuxtApi } from '@voix/voix-layer/composables/voixNuxtApi'
import { watch } from 'vue'

function setCurrentUserInStore(response: any) {
  if (response) {
    // Store the page data for use in the app
    const userStore = useUserStore()
    userStore.currentUser = response
  }
}

async function getCurrentUser() {
  const request = await $voixNuxtApi(
    `/api/voix/users/me`,
  )

  setCurrentUserInStore(request.data.value)

  return request
}

export { getCurrentUser }
